/* Applicants Count Graph Styling */
.applicants-count-container {
    background-color: #34db50;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    color: white;
    width: 250px;
    margin: 20px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .applicants-count-container h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .count-number {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 10px;
    color: white !important;
  }
  