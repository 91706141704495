/* Applicants Count Graph Styling */
.Rejected-count-container {
    background-color: #db3f34;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    color: white;
    width: 250px;
    margin: 20px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .Rejected-container h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .count-number {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .search-bar {
    padding: 10px;
    width: 300px;
    font-size: 1rem;
    border-radius: 20px;
    border: 1px solid #ccc;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 1rem;
  }
  
  .search-bar:focus {
    border-color: #3498db;
  }

  .action-btn {
    background-color: #1e6091; /* A pleasant green tone */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .action-btn:hover {
    background-color: #34a0a4; /* Slightly darker green for hover */
    transform: scale(1.05); /* Slight zoom-in effect */
  }
  
  .action-btn:active {
    background-color: #184e77; /* Even darker green for active state */
    transform: scale(0.95); /* Slight zoom-out effect for click feedback */
  }
  
  .action-btn:disabled {
    background-color: #ccc; /* Disabled gray background */
    color: #666; /* Disabled text color */
    cursor: not-allowed;
    transform: none; /* No hover or click effects */
  }

  .resume-link {
    background-color: #2980b9;
    padding: 5px 10px;
    border-radius: 20px;
    text-decoration: none;
    color: white;
    display: inline-block;
}
  