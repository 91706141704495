
  
  .count-number {
    font-size: 36px;
    font-weight: bold;
    color: black;
  }
  

  /* Applicants Count Graph Styling */
.hiring-count-container {
    background-color: #6634db !important;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    color: white;
    width: 250px;
    margin: 20px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
