/* General body styles without sign-in background */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

/* Scope the background specifically to the sign-in container or wrapper */
.sign-in-page {
    background: linear-gradient(135deg, #ffffff 50%, #2575fc 50%);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Sign-in container styles */
.sign-in-container {
    width: 100%;
    max-width: 400px;
    background: #fff;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
}

.sign-in-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: #000;
}

.sign-in-container form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sign-in-container form input {
    padding: 15px;
    border-radius: 30px;
    border: none;
    width:90%;
    background: #f0f0f0;
    font-size: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    outline: none;
}

.sign-in-container form input::placeholder {
    color: #aaa;
}

.sign-in-container button {
    padding: 15px;
    background-color: #2575fc;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 0 6px 15px rgba(207, 207, 207, 0.4);
    transition: all 0.3s ease;
    width: 50%;
    margin-left: auto;
    margin-right:auto;
}

.sign-in-container button:hover {
    background-color: #2574fcb4;
}

/* Logo styling */
.logo-container {
    margin-bottom: 20px;
}

.logo {
    width: 150px;
    height: auto;
    display: block;
    margin: 0 auto;
}
