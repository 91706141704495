.email-modal {
    width: 500px;
    margin: 0 auto;
    background-color: rgb(206, 206, 206);
    padding: 20px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    margin-top: 5rem;
}

.modal-content {
    width: 100%; /* Ensure the content takes full width */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content label {
    display: block;
    margin-bottom: 5px;
    width: 100%; /* Full width label */
    text-align: left; /* Align the text to the left */
}

.modal-content input,
.modal-content textarea,
.modal-content select { /* Added select here */
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Dropdown styling */
.modal-content select {
    background-color: white;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.modal-content select:hover {
    background-color: #f0f0f0;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Make the action buttons span the width */
}

.modal-actions button {
    padding: 10px 20px; /* Increase button width */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #3498db;
    color: white;
    flex: 1; /* Ensure the buttons stretch */
    margin: 0 5px; /* Space between buttons */
}

.modal-actions button:last-child {
    background-color: #e74c3c;
}
