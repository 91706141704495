/* General Layout */
.layout {
  display: flex;
}

/* Sidebar Styles */
.sidebar {
  width: 250px; /* Fixed full-width sidebar */
  height: 100vh;
  background: linear-gradient(180deg, #0a58ca 0%, #134eb0 100%); /* Background gradient */
  color: white;
  padding: 20px 0; /* Adjust padding to fit content */
  position: fixed; /* Sidebar stays fixed */
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px 20px 20px 0px;
  overflow: hidden; /* Prevent overflow */
  -webkit-box-shadow: 10px 2px 24px -12px rgba(115,115,115,1);
-moz-box-shadow: 10px 2px 24px -12px rgba(115,115,115,1);
box-shadow: 10px 2px 24px -12px rgba(115,115,115,1);
}

.sidebar-logo {
  font-size: 2rem;
  margin-bottom: 40px;
  opacity: 1;
}

/* Sidebar Menu */
.sidebar ul {
  list-style: none;
  padding: 0;
  width: 100%; /* Full width for clickable area */
  text-align: center;
}

.sidebar ul li {
  margin-bottom: 20px;
  width: 100%;
}

/* Menu Item Links */
.sidebar ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
  justify-content: flex-start;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
}

.sidebar ul li a i {
  font-size: 1.5rem;
  margin-right: 15px;
}

.sidebar ul li a:hover {
  background-color: #1f77d0; /* Hover background color */
}

/* Show text all the time (removed hover states) */
.sidebar ul li a span {
  opacity: 1; /* Text is always visible */
  margin-left: 10px;
}

.sidebar ul li a i {
  margin-right: 15px; /* Space between icon and text */
}

/* Active class for the current page */
.sidebar ul li a.active {
  background-color: #1f77d0;
  font-weight: bold;
}

/* Main Content Area */
.content {
  margin-left: 250px; /* Adjust according to fixed sidebar width */
  padding: 30px;
  width: calc(100% - 250px); /* Adjust width based on sidebar */
  background-color: #f4f6f9;
  transition: margin-left 0.3s ease; /* Smooth content shift */
}

h1 {
  font-size: 2rem;
  color: #333;
  text-align: left;
  margin-bottom: 20px;
}

.logout-section {
  margin-top: auto;
  margin-bottom: 1rem; /* Ensures the button sticks to the bottom */
  padding: 20px;
  margin-right: auto;
}

.logout-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  width: 100%;
  transition: background-color 0.3s ease;
  margin-right: auto;
}

.logout-btn:hover {
  background-color: #c0392b;
}

.user-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
}

.profile-picture-container {
  position: relative;
  display: inline-block;
}

.profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid white; /* Add a border to separate the image from the background */
}

.edit-icon {
  position: absolute;
  bottom: -5px; /* Move it slightly down */
  right: -5px; /* Move it slightly to the right */
  background-color: white !important;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  color: black;
  border: 1px solid #3498db;
}

.user-profile p {
  font-size: 1rem;
  color: #fff; /* Adjusted to white to contrast with the blue background */
  text-align: center;
  margin-top: 10px;
}

/* Sidebar.css */
.notification-badge {
  background-color: red;
  color: white;
  border-radius: 70%;
  padding: 5px 10px;
  font-size: 12px;
  margin-left: 10px;
  position: relative;
  top: -10px;
  right: 10px;
}