/* General Styles */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f6f9; /* Softer background */
    overflow-x: hidden;
}

.applicants-container {
    padding: 20px;
    width: 90%;
}

.counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
}

h1 {
    font-size: 2rem;
    color: #333;
    text-align: left;
    margin-bottom: 20px;
}

/* Table Styles */
.applicants-table {
    width: 100%;
    border-collapse: collapse; /* Make sure the table cells touch */
    margin-bottom: 20px;
}

.applicants-table th,
.applicants-table td {
    padding: 15px 20px;
    text-align: left;
    vertical-align: middle;
}

.applicants-table thead th {
    background-color: #2e3b4e;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
}

.applicants-table tbody tr {
    background-color: white;
    border-bottom: 1px solid #ddd;
    transition: all 0.2s;
    font-size: 14px;
}

.applicants-table tbody tr:hover {
    background-color: #f9f9f9; /* Highlight the row on hover */
}

.applicants-table td {
    border-bottom: none;
}

/* Input Field Styles for Edit Mode */
input[type="text"] {
    width: 90%; /* Ensure the input field fills the cell */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
}

input[type="text"]:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

/* Actions container with flexbox */
.actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px; /* Add space between buttons */
    flex-direction: row; /* Ensure the buttons are in a row */
}

/* Icon Button Styles */
.action-icon {
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3498db; /* Default background color */
    color: white;
    transition: background-color 0.2s ease;
}

.action-icon:hover {
    background-color: #2980b9; /* Slightly darker on hover */
}

/* Different icon colors based on action */
.edit-icon {
    background-color: hsl(204, 70%, 53%);
}

.delete-icon {
    background-color: #e74c3c;
}

.accept-icon {
    background-color: #2ecc71;
}

.reject-icon {
    background-color: #e74c3c;
}

.reject-btn {
    background-color: #e74c3c;
}

/* Expand button styling */
.expand-btn {
    background-color: #3498db;
    color: white;
    border-radius: 50%;
    font-size: 16px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    cursor: pointer;
}

.expand-btn:hover {
    
}

/* Expandable Row Styling */
.expandable-row {
    transition: all 0.3s ease-in-out;
}

.notes-section {
    display: flex;
    flex-direction: column;
    width: 99%; /* Ensures the notes section takes full width */
}

.notes-section h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.notes-section textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
}

.notes-section textarea:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.save-notes-btn {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-top: 10px;
    align-self: flex-end;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.save-notes-btn:hover {
    background-color: #2980b9;
}

/* Add transition for smooth open/close */
.expandable-row {
    transition: all 0.3s ease-in-out;
}

/* Responsive Design */
@media (max-width: 768px) {
    .applicants-container {
        width: 100%;
        padding: 10px;
    }

    .applicants-table th, 
    .applicants-table td {
        font-size: 12px;
        padding: 10px;
    }

    button {
        padding: 6px 8px;
    }
}

.resume-link {
    background-color: #2980b9;
    padding: 5px 10px;
    border-radius: 20px;
    text-decoration: none;
    color: white;
    display: inline-block;
}

.move-btn {
    background-color: rgb(221, 221, 221);
    border: none;
    padding: 10px;
    border-radius: 50px;
    font-size: 1.2rem;
    cursor: pointer;
}

.reject-btn {
    background-color: #e74c3c;
    color: white;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .move-btn .reject-btn:hover {
    background-color: #c0392b;
  }

  /* Pagination Controls Styles */
.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px; /* Space between the buttons and the page number */
}

.pagination-controls button {
    background-color: #3498db; /* Button background color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.pagination-controls button:hover {
    background-color: #2980b9; /* Hover color */
}

.pagination-controls button:disabled {
    background-color: #cccccc; /* Disabled button color */
    cursor: not-allowed;
}

.pagination-controls span {
    font-size: 16px;
    color: #333;
}

.schedule-modal {
    width: 500px; /* Adjust modal width */
    height: 400px; /* Adjust modal height */
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute; /* Center the modal */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .schedule-modal h2 {
    margin-bottom: 15px;
  }
  
  .schedule-modal button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .schedule-modal button:last-child {
    background-color: #e74c3c;
  }
  
  .schedule-modal button:hover {
    opacity: 0.9;
  }
  
  .schedule-modal input {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  /* Flexbox for even spacing between calendar and interview list */
  .calendar-interview-container {
    display: flex;
    gap: 20px;
    justify-content: space-between; /* Ensure both sections take equal space */
    margin-bottom: 20px;
  }

  .react-calendar {
    width: 100% !Important; /* Ensures the calendar takes full width */
    max-width: 100%; /* Prevents overflow */
    font-weight: 700;
    border-radius: 20px;
  }

  .react-calendar__tile--active {
    border-radius: 10px;
    background-color: #2980b9 !important;
  }
  
  .calendar-container,
  .interviews-list-container {
    flex: 1;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .interviews-list-container h3 {
    margin-bottom: 10px;
    font-size: 25px;
  }
  
  .interviews-list-container ul {
    list-style-type: none;
    padding-left: 0;
    background-color: #3498db;
    border-radius: 20px;
    color: white;
  }
  
  .interviews-list-container li {
    padding: 10px;
  }
  
  .interviews-list-container li:last-child {
    border-bottom: none;
  }
  
  /* Modal styling */
  .custom-modal {
    width: 500px;
    height: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .custom-modal h2 {
    margin-bottom: 20px;
  }
  
  .custom-modal button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .custom-modal button:hover {
    background-color: #2980b9;
  }
  
  /* Custom styles for the DatePicker calendar */
  .react-datepicker {
    font-size: 1.1rem;
  }
  
  .react-datepicker__header {
    padding-top: 10px;
  }
  
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    font-size: 1rem;
  }
  
  .react-datepicker__navigation {
    top: 15px;
  }
  
  .react-datepicker__month-container {
    width: 350px;
  }
  
  .react-datepicker__time-list {
    width: 100px;
  }
  
  .react-datepicker__navigation--previous {
    margin-left: 10px;
  }
  
  .react-datepicker__navigation--next {
    margin-right: 10px;
  }
  
  .react-datepicker__day--selected {
    background-color: #3498db;
  }

  /* Cancel button styling */
.interviews-list-container button {
    background-color: #e74c3c; /* Red color for the cancel button */
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 1rem;
}

.interviews-list-container button:hover {
    background-color: #c0392b; /* Darker red on hover */
}
  